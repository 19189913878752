// import i18n from '@/i18n'
import { harvestFrontCurrentStateService } from '@/business/harvestFrontCurrentStateService'
export default {
  name: 'HarvesterDialogComponent',
  components: {
  },
  props: {
    harvester:undefined,
    front:undefined,
    fromHistory:false,
  },
  data: () => ({
    isValidForm: false,
    harvestStopState:'stoped',
    stoppedTime:null,
  }),
  computed: {
  },
  created () {

  },
  async mounted () {
    // Setea el lenguaje de los componentes de Vuetify
    this.$vuetify.lang.current = this.$i18n.locale
    this.stoppedTime = this.harvester.stoppedTimeString;
  },
  methods: {

    cancel() {
      this.stoppedTime = null,
      this.close();
    },
    close(){
      this.$emit('closeDialog');
    },
    async save(){
      this.harvester.stoppedTimeString = this.stoppedTime;
      await harvestFrontCurrentStateService.timeStoppedHarvester(this.harvester.idThing,this.front.id,this.stoppedTime)
      this.close();
    },
    /**
     * Se ejecuta cuando cambian los datos de la tabla (por búsqueda, filtrado, agregar/quitar columnas, etc.)
     * @param {*} newData
     */
    dataChangeEventHandler () {

    },
    getData () {

    },
    
  },
  watch: {
    isValidForm(newVal, oldVal) {
      if (newVal !== oldVal) {
          this.$emit('update:validForm', newVal)
      }
    },
  }
}
