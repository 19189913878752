import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"front-dialog full-height full-width pb-0 pl-0 pr-2 mx-0"},[_c(VCard,{attrs:{"height":'100%'}},[_c(VCardTitle,{staticClass:"pa-0 thing-dialog-title"},[_c(VToolbar,{staticClass:"thing-dialog-toolbar",attrs:{"color":"primary","dark":""}},[_vm._v(" "+_vm._s(_vm.$t('smartTruckDispatcher.harvestFronts'))+" ")])],1),_c('keep-alive',[_c('div',{staticClass:"frontDialogBody"},[_c('div',{staticClass:"properties"},[_c('div',{staticClass:"property"},[_c('label',[_vm._v(_vm._s(_vm.$t('smartTruckDispatcher.truckModal.actualFront'))+": ")]),_c('span',[_vm._v(_vm._s(_vm.currentFront.name))])]),_c('div',{staticClass:"property"},[_c('label',[_vm._v(_vm._s(_vm.$t('smartTruckDispatcher.truckModal.category'))+": ")]),_c('span',[_vm._v(_vm._s(_vm.currentFront.category.name))])]),_c('div',{staticClass:"property"},[_c('label',[_vm._v(_vm._s(_vm.$t('smartTruckDispatcher.driveUnit'))+": ")]),_c('span',[_vm._v(_vm._s(_vm.currentFront.driveUnitName))])])])])]),_c(VCardActions,{staticClass:"px-2 pb-2 pt-0",attrs:{"id":"card-actions"}},[_c(VSpacer),(!_vm.currentFront.stopped && !_vm.fromHistory)?_c(VBtn,{attrs:{"color":"primary"},on:{"click":function($event){return _vm.StopFront()}}},[_vm._v(_vm._s(_vm.$t('smartTruckDispatcher.stopFront'))+" ")]):_vm._e(),(_vm.currentFront.stopped && !_vm.fromHistory)?_c(VBtn,{attrs:{"color":"primary"},on:{"click":function($event){return _vm.StartFront()}}},[_vm._v(_vm._s(_vm.$t('smartTruckDispatcher.startFront'))+" ")]):_vm._e(),_c(VBtn,{attrs:{"color":"primary"},on:{"click":function($event){return _vm.cancel()}}},[_vm._v(_vm._s(_vm.$t('close'))+" ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }