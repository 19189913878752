// import i18n from '@/i18n'
import { SnackbarStyle } from '@/constants/constants'
import { mapMutations, mapActions } from 'vuex'

export default {
  name: 'WaitEmptyTrucksComponent',
  components: {
  },
  props: {
    trucks:undefined
  },
  data: () => ({
    categories: [],
    loading: false,
    randomkey:0,
  }),
  computed: {
  },
  created () {
   
  },
  async mounted () {
    // Setea el lenguaje de los componentes de Vuetify
    this.$vuetify.lang.current = this.$i18n.locale  
    this.formateData();
  },
  methods: {
    /**
     * actions del breadcrumb
    */
    ...mapActions("breadcrumbs", ["pushBread", "pullBread"]),
    ...mapActions({
      'showSnackbar': 'snackbar/showSnackbar',
      'closeSnackbar': 'snackbar/closeSnackbar'
    }),
    ...mapMutations('dialog', {
      openDialog: 'openDialog',
      closeDialog: 'closeDialog'
    }),
    formateData(){
      this.groupByCategory();
    },
    groupByCategory(){
      this.loading = true;
      this.randomkey = Math.ceil(Math.random() * 1000000);
      this.categories = []
      const categories = this.trucks.map(truck => truck.category.name).filter(this.onlyUnique).sort((a, b) => a.localeCompare(b))
      categories.forEach(category => {
        this.categories.push({
          category: category,
          trucks: this.trucks.filter(truck => truck.category.name === category)
        });
        
      });
      this.categories.map(category => category.trucksLabel = category.trucks.map(truck => truck.identificator).join(' - '))
      this.loading = false;
    },
    onlyUnique(value, index, array) {
      return array.indexOf(value) === index;
    },
    notImplemented () {
      this.showSnackbar({ visible: true, text: this.$t('notImplemented'), timeout: 6000, style: SnackbarStyle.WARNING })
    },
  },
  watch: {
    trucks () {
      this.formateData();
    },
  }
}
