import { render, staticRenderFns } from "./TruckDispatchDialogComponent.vue?vue&type=template&id=195be74e&scoped=true"
import script from "./TruckDispatchDialogComponent.js?vue&type=script&lang=js&external"
export * from "./TruckDispatchDialogComponent.js?vue&type=script&lang=js&external"
import style0 from "./TruckDispatchDialogComponent.vue?vue&type=style&index=0&id=195be74e&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "195be74e",
  null
  
)

export default component.exports