import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VCardText,[_c(VForm,[_c('h3',[_vm._v(_vm._s(_vm.$t('smartTruckDispatcher.order')))]),_c(VRow,[_c(VRadioGroup,{staticClass:"ma-0 pa-0 full-width",attrs:{"row":""},model:{value:(_vm.orderBy),callback:function ($$v) {_vm.orderBy=$$v},expression:"orderBy"}},[_c(VCol,{attrs:{"cols":"12","sm":"6"}},[_c(VRadio,{attrs:{"label":` ${_vm.$t('smartTruckDispatcher.orderByNameAscending')}`,"value":_vm.nameAscendingValue}})],1),_c(VCol,{attrs:{"cols":"12","sm":"6"}},[_c(VRadio,{attrs:{"label":`${_vm.$t('smartTruckDispatcher.orderByNameDescending')}`,"value":_vm.nameDescendingValue}})],1),_c(VCol,{attrs:{"cols":"12","sm":"6"}},[_c(VRadio,{attrs:{"label":`${_vm.$t('smartTruckDispatcher.orderByCategoryAscending')}`,"value":_vm.categoryAscendingValue}})],1),_c(VCol,{attrs:{"cols":"12","sm":"6"}},[_c(VRadio,{attrs:{"label":`${_vm.$t('smartTruckDispatcher.orderByCategoryDescending')}`,"value":_vm.categoryDescendingValue}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }