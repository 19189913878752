export const ABLE_TRUCKS_HEADERS = [
    {
        type: "category", key: "category", field: "categoryId",
        headerStyle: { border: { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' } }, font: { bold: true, color: { argb: "FFF90425" } } },
        rowStyle: { alignment: { horizontal: 'left' }, border: { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' } } }
    },
    {
        type: "fleet", key: "fleet", field: "fleetId",
        headerStyle: { border: { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' } }, font: { bold: true, color: { argb: "FFF90425" } } },
        rowStyle: { alignment: { horizontal: 'left' }, border: { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' } } }
    },
    {
        type: "thing", key: "truck", field: "idThing",
        headerStyle: { border: { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' } }, font: { bold: true, color: { argb: "FFF90425" } }, alignment: { horizontal: 'center' } },
        rowStyle: { alignment: { horizontal: 'center' }, border: { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' } } }
    },
    {
        type: "string", key: "travels", field: "tripsCount",
        headerStyle: { border: { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' } }, font: { bold: true, color: { argb: "FFF90425" } }, alignment: { horizontal: 'center' } },
        rowStyle: { alignment: { horizontal: 'center' }, border: { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' } } }
    },
    {
        type: "timestampFormat", key: "outOfService",
        headerStyle: { border: { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' } }, font: { bold: true, color: { argb: "FFF90425" } }, alignment: { horizontal: 'center' } }, field: "outOfServiceTime",
        rowStyle: { alignment: { horizontal: 'center' }, border: { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' } } }
    }
]

export const OUT_OF_SERVICE_TRUCKS_HEADERS = [
    {
        type: "fleet", key: "fleet", field: "fleetId",
        headerStyle: { border: { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' } }, font: { bold: true, color: { argb: "FFF90425" } } },
        rowStyle: { alignment: { horizontal: 'left' }, border: { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' } } }
    },
    {
        type: "thing", key: "truck", field: "idThing",
        headerStyle: { border: { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' } }, font: { bold: true, color: { argb: "FFF90425" } }, alignment: { horizontal: 'center' } },
        rowStyle: { alignment: { horizontal: 'center' }, border: { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' } } }
    },
    {
        type: "string", key: "travels", field: "tripsCount",
        headerStyle: { border: { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' } }, font: { bold: true, color: { argb: "FFF90425" } }, alignment: { horizontal: 'center' } },
        rowStyle: { alignment: { horizontal: 'center' }, border: { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' } } }
    },
    {
        type: "timestampFormat", key: "outOfService", field: "outOfServiceTime",
        headerStyle: { border: { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' } }, font: { bold: true, color: { argb: "FFF90425" } }, alignment: { horizontal: 'center' } },
        rowStyle: { alignment: { horizontal: 'center' }, border: { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' } } }
    }
]

export const WITHOUT_ACTIVITY_TRUCKS_HEADERS = [
    {
        type: "fleet", key: "fleet", field: "fleetId",
        headerStyle: { border: { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' } }, font: { bold: true, color: { argb: "FFF90425" } } },
        rowStyle: { alignment: { horizontal: 'left' }, border: { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' } } }
    },
    {
        type: "thing", key: "truck", field: "idThing",
        headerStyle: { border: { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' } }, font: { bold: true, color: { argb: "FFF90425" } }, alignment: { horizontal: 'center' } },
        rowStyle: { alignment: { horizontal: 'center' }, border: { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' } } }
    },
    {
        type: "string", key: "travels", field: "tripsCount",
        headerStyle: { border: { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' } }, font: { bold: true, color: { argb: "FFF90425" } }, alignment: { horizontal: 'center' } },
        rowStyle: { alignment: { horizontal: 'center' }, border: { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' } } }
    },
    {
        type: "timestampFormat", key: "outOfService", field: "outOfServiceTime",
        headerStyle: { border: { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' } }, font: { bold: true, color: { argb: "FFF90425" } }, alignment: { horizontal: 'center' } },
        rowStyle: { alignment: { horizontal: 'center' }, border: { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' } } }
    }
]

export const TOTALS_STYLE = {
    font: { bold: true },
    border: {
        top: { style: 'medium' },
        bottom: { style: 'medium' },
        left: { style: 'medium' },
        right: { style: 'medium' }
    },
    fill: {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'ebebeb' }
    },
    alignmentRight: { horizontal: 'right' },
    alignmentLeft: { horizontal: 'left' }
}

export const SHIFTS_KEYS = [
    "harvestFront.shifts.first", "harvestFront.shifts.second", "harvestFront.shifts.third"
]