import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_vm.model.show)?_c(VDialog,{attrs:{"width":800,"id":`modal_${_vm.selectorId}`},on:{"click:outside":function($event){return _vm.cancel()}},model:{value:(_vm.model.show),callback:function ($$v) {_vm.$set(_vm.model, "show", $$v)},expression:"model.show"}},[_c('div',{staticClass:"modal-container"},[_c('div',{staticClass:"modal-header pa-0"},[_c(VToolbar,{attrs:{"color":"primary","dark":""}},[_vm._v(" "+_vm._s(_vm.$t('category.title'))+" "),_c(VSpacer),_c(VIcon,{attrs:{"id":`modal_${_vm.selectorId}_closeBtn`},on:{"click":function($event){return _vm.cancel()}}},[_vm._v("close")])],1)],1),_c('div',{staticClass:"modal-body"},[_c('CategoryABMComponent',{on:{"exit":_vm.exit}})],1),_c('div',{staticClass:"modal-footer"},[_c(VBtn,{attrs:{"id":`modal_${_vm.selectorId}_cancelBtn`,"width":"fit-content","color":"primary"},on:{"click":function($event){return _vm.cancel()}}},[_vm._v(" "+_vm._s(_vm.$t('exit'))+" ")])],1)])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }