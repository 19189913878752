import { render, staticRenderFns } from "./TruckDialogComponent.vue?vue&type=template&id=14d50fd0&scoped=true"
import script from "./TruckDialogComponent.js?vue&type=script&lang=js&external"
export * from "./TruckDialogComponent.js?vue&type=script&lang=js&external"
import style0 from "./TruckDialogComponent.vue?vue&type=style&index=0&id=14d50fd0&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "14d50fd0",
  null
  
)

export default component.exports