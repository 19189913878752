import { configurationService } from '@/business/configurationService'
import ConfigurationsConstants  from '@/constants/smartTruckDispatcher.constants'
export default {
  name: 'FrontOrderDialogComponent',
  components: {
  },
  props: {
    showDialog: Boolean,
  },
  data: () => ({
    configurationsId: ConfigurationsConstants.ConfigurationKeys.FRONTS_ORDER_KEY,
    orderBy: ConfigurationsConstants.Orders.NAME_ASCENDING,
    configurations: null,
    nameAscendingValue:ConfigurationsConstants.Orders.NAME_ASCENDING,
    nameDescendingValue:ConfigurationsConstants.Orders.NAME_DESCENDING,
    categoryAscendingValue:ConfigurationsConstants.Orders.CATEGORY_ASCENDING,
    categoryDescendingValue:ConfigurationsConstants.Orders.CATEGORY_DESCENDING
  }),
  computed: {
  },
  async mounted() {
    await this.getConfiguration();
  },
  created() {
    
  },
  methods: {
    async getConfiguration () {
      const config = await configurationService.get(this.configurationsId)
      if (config) {
        this.configurations = config;
        if(config.data.orderBy){
          this.orderBy = config.data.orderBy
        }
      }
    },
    async saveConfiguration () {
      await configurationService.save(this.configurationsId,{
        orderBy: this.orderBy,
      })
    },
  },
  watch: {
    orderBy () {
      this.saveConfiguration();
    },
  }
}