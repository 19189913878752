import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"trucks-assignment full-height full-width pt-1 pb-0 pl-0 pr-2 mx-0"},[_c(VCard,{staticClass:"elevation-1 full-height"},[_c(VCardTitle,{staticClass:"pa-0 thing-dialog-title"},[_c(VToolbar,{staticClass:"thing-dialog-toolbar",attrs:{"color":"primary","dark":""}},[_vm._v(" "+_vm._s(_vm.$t('smartTruckDispatcher.menu-trucksAssignment'))+" "),_c(VSpacer),_c(VIcon,{attrs:{"id":"closeButton"},on:{"click":function($event){return _vm.cancel()}}},[_vm._v("close")])],1)],1),_c(VCardText,{staticClass:"pa-2 px-4",attrs:{"id":"card-text"}},[_c(VForm,{ref:"formcategory"},[_c('h3',{staticClass:"mt-1"},[_vm._v(_vm._s(_vm.$t('smartTruckDispatcher.menu-trucksAssignment')))]),_c(VRow,[_c(VCol,{staticClass:"full-height full-width",attrs:{"cols":"12","sm":"12"}},[_c('SelectorTree',{attrs:{"selectorId":"type-selector"},model:{value:(_vm.trucksToAssignModel),callback:function ($$v) {_vm.trucksToAssignModel=$$v},expression:"trucksToAssignModel"}})],1)],1)],1)],1),_c(VCardActions,{staticClass:"px-2 pb-2 pt-0",attrs:{"id":"card-actions"}},[_c(VSpacer),_c(VBtn,{attrs:{"width":"fit-content","color":"primary"},on:{"click":function($event){return _vm.cancel()}}},[_vm._v(" "+_vm._s(_vm.$t('cancel'))+" ")]),_c(VBtn,{attrs:{"width":"fit-content","color":"primary"},on:{"click":function($event){return _vm.save()}}},[_vm._v(" "+_vm._s(_vm.$t('save'))+" ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }