import SelectorTree from '@/components/commons/selector/dialog/tree/SelectorTree.vue'
import thingApi from '@/api/thing.api'
import { configurationService } from '@/business/configurationService'
import ConfigurationsConstants  from '@/constants/smartTruckDispatcher.constants'
import FrontOrderDialogComponent from '@/components/smart-truck-dispatcher/front-order-dialog/FrontOrderDialogComponent.vue'
import { mapActions } from 'vuex'
export default {
  name: 'FrontSelectorDialogComponent',
  components: {
    SelectorTree,
    FrontOrderDialogComponent
  },
  props: {
    showDialog: Boolean,
  },
  data: () => ({
    selectedFrontconfigurationsId: ConfigurationsConstants.ConfigurationKeys.SELECTED_FRONTS_KEY,
    harvestFrontSelectorModel: {
      normalizer: (node) => {
        return {
          id: node._id,
          label: node.name,
          children: node.children
        }
      },
      data: [],
      selected: null,
      show: true,
      singleSelect: false,
      selectAll: false,
    },
    selectedFronts:[],
    configurations:null,
    harvestFronts:[],
  }),
  computed: {
  },
  async mounted() {
    await this.getHarvestFrontsData();
    await this.getConfiguration();
  },
  created() {
    
  },
  methods: {
    ...mapActions({
      'showSnackbar': 'snackbar/showSnackbar',
      'closeSnackbar': 'snackbar/closeSnackbar'
    }),
    cancel () {
      this.$emit('cancel')
    },
    async save () {
      await this.saveConfiguration();
      this.cancel();
    },
    async getHarvestFrontsData() {
      this.enterprise = JSON.parse(localStorage.getItem('enterpriseId'))
      this.harvestFronts = (await thingApi.getHarvestFronts()).data.filter(t => !t.deleted && t.enterpriseId == this.enterprise).sort((a, b) => a.name.localeCompare(b.name))
      this.harvestFrontSelectorModel.data = this.harvestFronts;
    }, 
    async getConfiguration () {
      const config = await configurationService.get(this.selectedFrontconfigurationsId)
      if (config) {
        this.configurations = config;
        if(config.data.selectedFronts){
          this.selectedFronts = config.data.selectedFronts
        }
      }
      this.applyConfigurations ();
    },
    async saveConfiguration () {
      const selectedFronts =  this.harvestFrontSelectorModel.selected;
      this.selectedFronts = [];
      const harvestFrontsIds = this.harvestFronts.map(hf => hf._id)
      harvestFrontsIds.forEach(element => {
        if(selectedFronts.includes(element)){
          this.selectedFronts.push(element);
        }
      });
      await configurationService.save(this.selectedFrontconfigurationsId,{
        selectedFronts: this.selectedFronts
      })
    },
    
    applyConfigurations () {
      let frontsToSelect = [];
      const harvestFrontsIds = this.harvestFronts.map(hf => hf._id)
      harvestFrontsIds.forEach(element => {
        if(this.selectedFronts.includes(element)){
          frontsToSelect.push(element);
        }
      });
      this.harvestFrontSelectorModel.selected = frontsToSelect;
    },
  },
  watch: {
   
  }
}