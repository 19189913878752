// import i18n from '@/i18n'

import SelectorTree from '@/components/commons/selector/dialog/tree/SelectorTree.vue'
import { harvestFrontCurrentStateService } from '@/business/harvestFrontCurrentStateService'
import thingService from '@/business/thingService';
import { get_Ids,getItemsBy_Id } from '@/tools/functions'
import { SnackbarStyle } from '@/constants/constants'
import { mapActions } from 'vuex'
export default {
  name: 'TrucksAssignmentComponent',
  components: {
    SelectorTree
  },
  props: {

  },
  data: () => ({
    search: null,
    trucksModel:null,
    trucksSchema: [],
    trucksToAssignModel: {
      normalizer: (node) => {
        return {
          id: node._id,
          label: node.name + (node.stateName? ' - '+node.stateName : ''),
          children: node.children
        }
      },
      data: [],
      selected: [],
      show: true,
      singleSelect: false,
      selectAll: false
    },
    trucksToAssign : [],
  }),
  computed: {
   
  },
  created () {

  },
  async mounted () {
    // Setea el lenguaje de los componentes de Vuetify
    this.$vuetify.lang.current = this.$i18n.locale
    await this.getData();
  },
  methods: {
    ...mapActions({
      'showSnackbar': 'snackbar/showSnackbar',
      'closeSnackbar': 'snackbar/closeSnackbar'
    }),
    cancel() {
      this.close();
    },
    close(){
      this.$emit('closeDialog');
    },
    
    async save(){
      let itemsSelected = []
      getItemsBy_Id(this.trucksToAssign,this.trucksToAssignModel.selected,itemsSelected)
      let trucksAssigned = []
      get_Ids(itemsSelected,trucksAssigned)
      //filtro los ids para que no esten repetidos
      const uniqueIds = trucksAssigned.filter(this.onlyUnique);
      await harvestFrontCurrentStateService.assignTrucks(uniqueIds).then(() => {
        this.close();
      })
    },
    async getData () {
      this.trucksToAssign = (await thingService.getAllTrucksNestedByGroupAndCategories());
      this.trucksToAssignModel.data = this.trucksToAssign
      const trucksAssignedObj = await harvestFrontCurrentStateService.getAsignedTrucks(); 
      this.trucksAssigned = trucksAssignedObj? trucksAssignedObj.trucksAssigned : [];
      this.trucksToAssignModel.selected = this.trucksAssigned || null

      let trucksAvailablesIds = []
      this.trucksToAssign.forEach(category => {
        category.children.forEach(group => {
          group.children.forEach(truck => {
            trucksAvailablesIds.push(truck._id)
          });
        });
      });

      const trucksNotAvailables = this.trucksAssigned.filter(truckId=> !trucksAvailablesIds.includes(truckId))
     if(trucksNotAvailables.length > 0){
      this.showSnackbar({ visible: true, text: this.$t('smartTruckDispatcher.notEnoughPermissions'), timeout: 9500, style: SnackbarStyle.ERROR })
      this.cancel();
     }
     
    },
    onlyUnique(value, index, array) {
      return array.indexOf(value) === index;
    },
    
  },
  watch: {

  }
}
