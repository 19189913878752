import CategoryABMComponent from "./category-abm/CategoryABMComponent.vue"

export default {
  name: 'CategoryDialogComponent',  
  model: {
    prop: 'model',
    event: 'change'
  },
  props: {
    model: {
      type: Object,
      required: true
    },
 },
  components: {
    CategoryABMComponent
  },
  data () {
    return {}
  },
  computed: {
  },
  mounted () {
  },
  methods: {
    cancel () {
      this.$emit('cancel')
    },
    exit () {
      this.cancel();
    }
  },
  watch: {
  }
}
