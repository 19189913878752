import service from '@/middleware'

const shiftSummaryReportApi = {
  async getReport(from, to) {
    const body = {
      from,
      to
    }
    const { data } = await service.http.post('/api/smart-truck-dispatcher-reports/shift-summary-report', body)
    return data;
  }
}

export default shiftSummaryReportApi
