import i18n from '@/i18n'
import harvestFrontCurrentStateApi from '@/api/harvestFrontCurrentState.api'
import ConfigurationsContants from '@/constants/smartTruckDispatcher.constants'

export default {
  name: 'DeviationDialogComponent',
  model: {
    prop: 'model',
    event: 'change'
  },
  props: {
    model: {
      type: Object,
      required: true,
    },
   enterprise: String
 },
  components: {
  },
  data:() => ({    
    deviationTimesKey: ConfigurationsContants.HARVEST_FRONT_DEVIATION_TIMES_KEY,
    validForm: false,
    validationRules: {
      positive: value => !value || value >= 0 || `${i18n.t('positive')}`,
    },
    deviationTime: { 
      id: undefined,
      data: {
        timeInCanchon: undefined,
        timeInTravel: undefined,
        timeInHarvestFront: undefined,
        timeInReturn: undefined
      }    
    }
  }),
  computed: {
  },
  mounted() {
    this.getData()
  },
  created() {
  },
  methods: {
    preventInvalidInput(evt) {
      evt = evt || window.event;
      const charCode = evt.which ? evt.which : evt.keyCode;
      // Previene negativos signo (-), 'E', y 'e'
      if (charCode === 45 || charCode === 69 || charCode === 101) {
        evt.preventDefault();
      }
    },
    async getData() {
      await this.getDeviationTimesConfiguration()
    },
    async getDeviationTimesConfiguration(){
      await harvestFrontCurrentStateApi.getConfiguration(this.deviationTimesKey).then(response => {
        if(response)
          this.deviationTime.data = response
      })     
    },
    cancel () {
      this.$emit('closeDialog')
      this.resetData()
      this.getData()
    },
    save () {
     this.deviationTime.id = this.deviationTimesKey
     this.$emit('saveDialog', this.deviationTime)
    },
    resetData(){
      this.deviationTime = { 
        id: undefined,
        data: {
          timeInCanchon: undefined,
          timeInTravel: undefined,
          timeInHarvestFront: undefined,
          timeInReturn: undefined
        }    
      }
    }
  },
  watch: {
  }
}
