import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"harvester-dialog full-height full-width pb-0 pl-0 pr-2 mx-0"},[_c(VCard,{attrs:{"height":'100%'}},[_c(VCardTitle,{staticClass:"pa-0 thing-dialog-title"},[_c(VToolbar,{staticClass:"thing-dialog-toolbar",attrs:{"color":"primary","dark":""}},[_c('div',{staticClass:"myHarvesterIcon"}),_vm._v(" "+_vm._s(_vm.$t('smartTruckDispatcher.harvester'))+" ")])],1),_c('keep-alive',[_c('div',{staticClass:"harvester-dialog-body"},[(_vm.harvester.state)?_c('label',[_vm._v(" "+_vm._s(_vm.$t('smartTruckDispatcher.harvesterStatus'))+" - "+_vm._s(_vm.harvester.state))]):_vm._e(),(_vm.harvester.working)?_c('label',[_vm._v(" "+_vm._s(_vm.$t('smartTruckDispatcher.velocity'))+" "+_vm._s(_vm.harvester.velocity))]):_vm._e(),(_vm.harvester.stopped && !_vm.fromHistory)?_c('div',[_c(VRow,[_c(VCol,{staticClass:"py-1",attrs:{"cols":"12","sm":"14","md":"14"}},[_c(VTextField,{attrs:{"outlined":"","dense":"","label":`${_vm.$t('smartTruckDispatcher.estimatedStoppedTime')}`},model:{value:(_vm.stoppedTime),callback:function ($$v) {_vm.stoppedTime=$$v},expression:"stoppedTime"}})],1)],1)],1):_vm._e()])]),_c(VCardActions,{staticClass:"px-2 pb-2 pt-0",attrs:{"id":"card-actions"}},[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary"},on:{"click":function($event){return _vm.cancel()}}},[_vm._v(_vm._s(_vm.$t('close')))]),_c(VBtn,{attrs:{"color":"primary"},on:{"click":function($event){return _vm.save()}}},[_vm._v(_vm._s(_vm.$t('save')))])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }