import { harvestFrontCurrentStateService } from '@/business/harvestFrontCurrentStateService'
import { SnackbarStyle } from '@/constants/constants'
import { mapActions } from 'vuex'
export default {
  name: 'TruckDispatchDialogComponent',
  components: {
    
  },
  props: {
    selectedTruckToDispatch:undefined,
    currentFront:undefined,
    trucksIncludedInOtherFrontsIds:undefined,
  },
  data: () => ({
    isValidForm: false,
    forceDispatch: false,
  }),
  computed: {
  },
  created () {

  },
  async mounted () {
    // Setea el lenguaje de los componentes de Vuetify
    this.$vuetify.lang.current = this.$i18n.locale
    if(this.trucksIncludedInOtherFrontsIds.includes(this.selectedTruckToDispatch.idThing)){
      this.forceDispatch = true;
    }
    else{
      this.forceDispatch = false;
    }
  },
  methods: {
    ...mapActions({
      'showSnackbar': 'snackbar/showSnackbar',
      'closeSnackbar': 'snackbar/closeSnackbar'
    }),
    cancel() {
      this.close();
    },
    close(){
      this.$emit('closeDialog');
    },
    
    async dispatch(){
      if(this.forceDispatch){
        await harvestFrontCurrentStateService.dispatchTruckForced(this.selectedTruckToDispatch,this.currentFront)
      }
      else{
        await harvestFrontCurrentStateService.dispatchTruck(this.selectedTruckToDispatch,this.currentFront)
      }
      this.showSnackbar({ visible: true, text: this.$t('smartTruckDispatcher.dispatchedTruck'), timeout: 9500, style: SnackbarStyle.SUCCESS })
      this.close();
    },
    cancelDispatch(){
      this.close();
    },
  },
  watch: {
    isValidForm(newVal, oldVal) {
      if (newVal !== oldVal) {
          this.$emit('update:validForm', newVal)
      }
    },
  }
}
