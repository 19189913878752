import i18n from '@/i18n'
import enterpriseApi from '@/api/enterprise.api'
import thingApi from '@/api/thing.api'
import SelectorTree from '@/components/commons/selector/dialog/tree/SelectorTree.vue'
import { SnackbarStyle } from '@/constants/constants'
import { mapActions } from 'vuex'

export default {
  name: 'CategoryABMDialogComponent',
  components: {
    SelectorTree
  },
  props: {
    showDialog: Boolean,
    edit: Boolean,
    category: Object,
    enterprise: String
  },
  data: () => ({
    validForm: false,
    validationRules: {
      required: (value) => !!value || `${i18n.t('required')}`,
    },
    truckSelectorModel: {
      normalizer: (node) => {
        return {
          id: node._id,
          label: node.name + (node.category ? ' - ' + node.category.name : ''),
          children: node.children
        }
      },
      data: [],
      selected: null,
      show: true,
      singleSelect: false,
      selectAll: false, 
    },
    harvestFrontSelectorModel: {
      normalizer: (node) => {
        return {
          id: node._id,
          label: node.name + (node.category ? ' - ' + node.category.name : ''),
          children: node.children
        }
      },
      data: [],
      selected: null,
      show: true,
      singleSelect: false,
      selectAll: false
    },
    showCategorizedTrucks: false,
    showCategorizedHarvestFronts: false,
    categories: [],
    trucks: [],
    harvestFronts: [],
  }),
  computed: {
  },
  mounted() {
    this.resetFields()
    this.$refs.formcategory.resetValidation()
    this.loadSelector()
  },
  created() {
    this.loadSelector()
  },
  methods: {
    ...mapActions({
      'showSnackbar': 'snackbar/showSnackbar',
      'closeSnackbar': 'snackbar/closeSnackbar'
    }),
    cancel () {
      this.$emit('closeDialog')
      this.resetFields()
      this.$refs.formcategory.resetValidation()
    },
    save () {
      this.category.trucks = this.truckSelectorModel.selected
      this.category.harvestFronts = this.harvestFrontSelectorModel.selected
      this.$emit('saveDialog', this.category)
      this.resetFields()
      this.$refs.formcategory.resetValidation()
    },
    async loadSelector () {
      this.enterprise = JSON.parse(localStorage.getItem('enterpriseId'))
      await this.getTrucksData()
      await this.getHarvestFrontsData()
      await this.getAllCategoriesData() 
      this.showCategorizedHarvestFronts = false
      this.showCategorizedTrucks = false
      this.truckSelectorModel.selected = this.category.trucks || null
      this.harvestFrontSelectorModel.selected = this.category.harvestFronts || null
      this.truckSelectorModel.selectAll = false
      this.harvestFrontSelectorModel.selectAll = false       
      this.FillTrucksSelector()
      this.FillHarvestFrontsSelector()
      //this.$refs.formcategory.resetValidation()
    },
    resetFields () {
      // this.$refs.truckSelector.resetSearch();
      // this.$refs.harvestFrontSelector.resetSearch();
      this.showCategorizedHarvestFronts = false
      this.showCategorizedTrucks = false
      this.titleNew = null
      this.truckSelectorModel.selected = null
      this.truckSelectorModel.selectAll = false
      this.harvestFrontSelectorModel.selected = null
      this.harvestFrontSelectorModel.selectAll = false
      this.categories = []
    },
    async getTrucksData() {
      this.trucks = (await thingApi.getAllTrucks()).data.filter(t => t.deleted !== true).sort((a, b) => a.name.localeCompare(b.name))
    },
    async getHarvestFrontsData() {
      this.harvestFronts = (await thingApi.getHarvestFronts()).data.filter(t => t.deleted !== true && t.enterpriseId == this.enterprise).sort((a, b) => a.name.localeCompare(b.name))      
    }, 
    async getAllCategoriesData() {
      enterpriseApi.getCategoriesPaginated(0,0,this.enterprise).then(response => {
        this.categories = response.data.data

        const trucksIds = this.trucks.map(c=>c._id)

        this.categories.forEach(category => {
          category.trucks.forEach(truck => {
            if(!trucksIds.includes(truck)){
              this.showSnackbar({ visible: true, text: this.$t('smartTruckDispatcher.notEnoughPermissions'), timeout: 9500, style: SnackbarStyle.ERROR })
              this.$emit('exit')
            }
          });
        });

        this.trucks = this.trucks.map(truck => {
          let category = this.categories.filter(c => c.trucks.includes(truck._id) && !c.deleted)[0]
          return {
            ...truck,
            category
          };
        })
        this.harvestFronts = this.harvestFronts.map(harvestFront => {
          let category = this.categories.filter(c => c.harvestFronts.includes(harvestFront._id) && !c.deleted)[0]
          return {
            ...harvestFront,
            category
          };
        })
    })

    },
    FillTrucksSelector () {
      if(!this.showCategorizedTrucks)  
        this.truckSelectorModel.data.cleanAndUpdate(this.trucks.filter(truck => !truck.category  || ( this.category && truck.category._id === this.category._id) ))
      else
        this.truckSelectorModel.data.cleanAndUpdate(this.trucks);
    },
    FillHarvestFrontsSelector () {
      if(!this.showCategorizedHarvestFronts)  
        this.harvestFrontSelectorModel.data.cleanAndUpdate(this.harvestFronts.filter(harvestFront => !harvestFront.category  || ( this.category && harvestFront.category._id === this.category._id) ))
      else
        this.harvestFrontSelectorModel.data.cleanAndUpdate(this.harvestFronts);
    }
  },
  watch: {
    category () {
      this.loadSelector()
    },
    trucks () {       
      this.FillTrucksSelector()
    },
    harvestFronts () {       
      this.FillHarvestFrontsSelector()
    },
    showCategorizedTrucks (){
      this.FillTrucksSelector()
    },
    showCategorizedHarvestFronts(){
      this.FillHarvestFrontsSelector()
    }
  }
}