// import i18n from '@/i18n'
import AutocompleteComponent from '@/components/commons/autocomplete/AutocompleteComponent.vue'
import { harvestFrontCurrentStateService } from '@/business/harvestFrontCurrentStateService'
export default {
  name: 'FrontDialogComponent',
  components: {
    AutocompleteComponent,
  },
  props: {
    currentFront:undefined,
    fromHistory:false,
  },
  data: () => ({
    isValidForm: false,
  }),
  computed: {
  },
  created () {

  },
  async mounted () {
    // Setea el lenguaje de los componentes de Vuetify
    this.$vuetify.lang.current = this.$i18n.locale
    
  },
  methods: {
    cancel() {
      this.close();
    },
    close(){
      this.$emit('closeDialog');
    },
    async StopFront(){
      await harvestFrontCurrentStateService.stopHarvestFront(this.currentFront)
      this.close()
    },
    async StartFront(){
      await harvestFrontCurrentStateService.removeStopHarvestFront(this.currentFront)
      this.close()
    },

    /**
     * Se ejecuta cuando cambian los datos de la tabla (por búsqueda, filtrado, agregar/quitar columnas, etc.)
     * @param {*} newData
     */
    dataChangeEventHandler () {

    },
    getData () {
    },
  
  },
  watch: {
    isValidForm(newVal, oldVal) {
      if (newVal !== oldVal) {
          this.$emit('update:validForm', newVal)
      }
    },
  }
}
