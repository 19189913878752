import i18n from '@/i18n'
import SelectorDialog from '@/components/commons/selector/dialog/SelectorDialog.vue'
import { filterRulesMapped } from '@/tools/filterRules'
import enterpriseApi from '@/api/enterprise.api'
import { mapActions, mapMutations } from 'vuex'
import { DialogType, SnackbarStyle } from '@/constants/constants'
import ListTreeViewComponent from '@/components/commons/list/tree-view/ListTreeViewComponent.vue'
import CategoryABMDialogComponent from './dialog/CategoryABMDialogComponent.vue'
//import { privilegesService } from '@/business/privilegesService'

export default {
  name: 'CategoryABMComponent',
  components: {
    ListTreeViewComponent,
    SelectorDialog,
    CategoryABMDialogComponent
  },
  data: () => ({
    tableHeaders: [],
    tableFilterRules: {},
    tableData: [],
    tableDataKey: '_id',
    rowActions: [],
    tableComponentButtons: [],
    pageSize: undefined,
    pageNumber: undefined,
    pageTotal: undefined,
    showEnterpriseSelector: false,
    selectedEnterprise: undefined,
    showABMDialog: false,
    edit: false,
    categoryDto: { 
      name: undefined,     
      enterpriseId: undefined, 
      trucks: [],
      harvestFronts: [],
      deleted: false     
    }
  }),
  computed: {
  },
  created () {
    // Botón de eliminación múltiple
    this.tableComponentButtons.push(
      {
        id: 'edit-dialog',
        tooltip: i18n.t('add'),
        icon: 'add',
        action: this.showDialog.bind(this),
        selectedData: false
      },
      {
        id: 'masive-delete',
        tooltip: i18n.t('category.multipleDelete'),
        icon: 'delete_outline',
        action: this.multipleDelete.bind(this),
        selectedData: true
      })
      this.rowActions.push(
        {
          id: 1,
          name: i18n.t('modify'),
          color: 'blue',
          icon: 'fa-pen',
        },
        {
          id: 2,
          name: i18n.t('delete'),
          color: 'red',
          icon: 'fa-trash-alt',
        },
      )
    this.getEnterprises()
    this.resetFields()
    this.getData()
  },
  methods: {
    ...mapActions({
      'showSnackbar': 'snackbar/showSnackbar',
      'closeSnackbar': 'snackbar/closeSnackbar'
    }),
    ...mapMutations('dialog', {
      openDialog: 'openDialog',
      closeDialog: 'closeDialog'
    }),
    /**
     * Refrescar la data
     */
    refreshTable () {
      this.getData(true)
    },
    getEnterprises () {
        const enterpriseId = JSON.parse(localStorage.getItem('enterpriseId'))
        this.selectedEnterprise = enterpriseId
    },
    async getData (refresh = false) {
      this.loadingTable = true
      this.pageTotal = await enterpriseApi.getTotalCountCategories(this.selectedEnterprise)
      if (!refresh) {
        this.pageSize = 50
        this.pageNumber = 1
      }
      enterpriseApi.getCategoriesPaginated(this.pageSize, this.pageNumber, this.selectedEnterprise).then(response => {
        this.tableData.cleanAndUpdate(response.data.data)
        if (!refresh) {
          this.tableHeaders.cleanAndUpdate(response.data.headers)
          this.setTableFilterRules()
        }
        this.loadingTable = false
      })
    },
    setTableFilterRules () {
      this.tableFilterRules = {}
      this.tableHeaders.forEach(header => {
        this.tableFilterRules[header.value] = filterRulesMapped[header.filterType]
      })
    },
    async delete (data) {
      await enterpriseApi.deleteCategory(data._id)
      this.getData(true)      
      this.resetFields()
    },
    async multipleDelete (data) {
      this.openDialog({
        title: this.$t('delete'),
        text: this.$t('category.multipleDeleteMessage'),
        type: DialogType.QUESTION,
        overlayOpacity: 0.5,
        actionButtons: true,
        yesAction: async () => {
          const ids = data.map(e => e._id)
          await enterpriseApi.multipleDeleteCategories(ids)
          this.getData(true)          
           this.resetFields()
          this.$refs['category-table'].resetSelected()
        },
        noAction: () => { }
      })
    },
    rowButtonClicked (button, data) {
      switch (button.id) {
        case 1:
          this.showABMDialog = true
          this.categoryDto = data
          this.edit = true
          // editar
          break
        case 2:
          this.openDialog({
            title: this.$t('delete'),
            text: this.$t('category.deleteMessage', { id: data._id, name: data.name }),
            type: DialogType.QUESTION,
            overlayOpacity: 0.5,
            actionButtons: true,
            yesAction: () => { this.delete(data) },
            noAction: () => { }
          })
          break
        default:
          console.log('default')
      }
    },
    showDialog () {
      this.showABMDialog = true
      this.edit = false
    },
    externalPaginator (pageSize, pageNumber) {
      this.pageNumber = Number(pageNumber)
      this.pageSize = Number(pageSize)
    },
    closeDialog () {
      this.showABMDialog = false
      this.resetFields()
      this.getData(true)
    },
    exit (){
      this.$emit('exit')
      this.closeDialog();
    },
    async saveDialog (category) {
      if (this.edit) {
        await enterpriseApi.updateCategory(category).then(() => {
          this.showABMDialog = false          
          this.resetFields()
          this.showSnackbar({ visible: true, text: this.$t('category.updateSuccess'), timeout: 9500, style: SnackbarStyle.SUCCESS })
        })
      } else {
        await enterpriseApi.createCategory(category).then(() => {
          this.showABMDialog = false          
          this.resetFields()
          this.showSnackbar({ visible: true, text: this.$t('category.saveSuccess'), timeout: 9500, style: SnackbarStyle.SUCCESS })
        })
      }
      this.getData(true)
    },
    resetFields () {
      this.edit = false
      this.categoryDto = {
        name: undefined,
        enterpriseId: this.selectedEnterprise, 
        trucks: [],
        harvestFronts: [],
        deleted: false
      }
    }
  }
}